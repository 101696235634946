// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Role from "../../../models/Role.res.js";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as Picture from "../../../styleguide/forms/Picture/Picture.res.js";
import * as BlogPost from "../../../models/BlogPost.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Breadcrumb from "../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as Cloudinary from "../../../libs/Cloudinary.res.js";
import * as LatestPosts from "../common/latest-posts/LatestPosts.res.js";
import * as Routes_Page from "../../../routes/common/Routes_Page.res.js";
import * as TopServices from "../../common/top-services/TopServices.res.js";
import * as BlogNewsletter from "../common/blog-newsletter/BlogNewsletter.res.js";
import * as Routes_BlogPost from "../../../routes/common/Routes_BlogPost.res.js";
import Format from "date-fns/format";
import * as DownloadResources from "../../resources/download-resources/DownloadResources.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogPostShowScss from "./BlogPostShow.scss";
import DatacentersX180Png from "Images/datacenters-180x180.png";

var css = BlogPostShowScss;

function imageUrl(publicId) {
  return "url(" + (Cloudinary.Url.build("Auto", {
                hd: "Dpr",
                tl: {
                  hd: "Quality",
                  tl: {
                    hd: {
                      TAG: "Crop",
                      _0: "Fill"
                    },
                    tl: {
                      hd: {
                        TAG: "Width",
                        _0: 120
                      },
                      tl: {
                        hd: {
                          TAG: "Height",
                          _0: 120
                        },
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }, Cloudinary.imageUrl(publicId)) + ")");
}

var Helpers = {
  imageUrl: imageUrl
};

function BlogPostShow$Component(props) {
  var blogPost = props.blogPost;
  var tmp;
  tmp = props.userRole === "Admin" ? JsxRuntime.jsx(Breadcrumb.make, {
          breadcrumbLinks: [
            {
              linkName: "Home",
              linkPath: "/"
            },
            {
              linkName: "News",
              linkPath: "/news"
            },
            {
              linkName: $$String.capitalize_ascii(blogPost.title),
              linkPath: blogPost.url
            },
            {
              linkName: "Edit",
              linkPath: Routes_BlogPost.Dashboard.edit(blogPost.id)
            }
          ]
        }) : JsxRuntime.jsx(Breadcrumb.make, {
          breadcrumbLinks: [
            {
              linkName: "Home",
              linkPath: "/"
            },
            {
              linkName: "News",
              linkPath: "/news"
            },
            {
              linkName: $$String.capitalize_ascii(blogPost.title),
              linkPath: blogPost.url
            }
          ]
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                tmp,
                                JsxRuntime.jsxs("section", {
                                      children: [
                                        JsxRuntime.jsx("h1", {
                                              children: blogPost.title,
                                              className: css.blogTitle
                                            }),
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx("span", {
                                                      children: Format(blogPost.publishedAt, "d MMM yyyy")
                                                    }),
                                                JsxRuntime.jsx("span", {
                                                      children: " by Datacenters.com " + blogPost.author
                                                    })
                                              ],
                                              className: css.publishedMeta
                                            }),
                                        JsxRuntime.jsx(Picture.make, {
                                              src: blogPost.featuredImage,
                                              large: [
                                                900,
                                                700
                                              ],
                                              imageClassName: css.featureImage
                                            }),
                                        JsxRuntime.jsx("div", {
                                              className: Cx.cx([
                                                    css.postContent,
                                                    "text-gray",
                                                    "mb-4"
                                                  ]),
                                              dangerouslySetInnerHTML: {
                                                __html: blogPost.body
                                              }
                                            }),
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx("div", {
                                                      children: JsxRuntime.jsx("div", {
                                                            className: css.authorImg,
                                                            style: {
                                                              backgroundImage: "url(" + DatacentersX180Png + ")"
                                                            }
                                                          }),
                                                      className: css.authorImgContainer
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        JsxRuntime.jsx("p", {
                                                              children: "Author",
                                                              className: css.authorHeader
                                                            }),
                                                        JsxRuntime.jsx("p", {
                                                              children: "Datacenters.com " + blogPost.author + " ",
                                                              className: css.authorName
                                                            }),
                                                        JsxRuntime.jsx("div", {
                                                              className: css.authorDescription,
                                                              dangerouslySetInnerHTML: {
                                                                __html: blogPost.authorDescription
                                                              }
                                                            }),
                                                        JsxRuntime.jsx(Button.AsLink.make, {
                                                              href: Routes_Page.contact,
                                                              size: "SM",
                                                              color: "Primary",
                                                              onClick: (function (param) {
                                                                  window.location.hash = "qualification";
                                                                }),
                                                              children: "Talk to Expert"
                                                            })
                                                      ],
                                                      className: css.authorMetaContainer
                                                    })
                                              ],
                                              className: css.authorContainer
                                            })
                                      ],
                                      className: css.blogPost
                                    })
                              ],
                              className: css.blogPostContent
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(DownloadResources.make, {}),
                                JsxRuntime.jsx(TopServices.make, {}),
                                JsxRuntime.jsx(LatestPosts.make, {
                                      latestPosts: props.latestPosts,
                                      title: "Latest Posts"
                                    }),
                                JsxRuntime.jsx(LatestPosts.make, {
                                      latestPosts: props.trendingPosts,
                                      title: "Trending Posts"
                                    })
                              ],
                              className: css.sidebar
                            })
                      ],
                      className: css.blogPostContainer
                    }),
                JsxRuntime.jsx(BlogNewsletter.make, {
                      orientation: "Row",
                      borderRadius: "Flat"
                    })
              ],
              className: css.blogPostLayout
            });
}

var Component = {
  make: BlogPostShow$Component
};

function BlogPostShow$default(props) {
  var blogPost = BlogPost.fromJs(props.blogPost);
  var latestPosts = Belt_Array.map(props.latestPosts, BlogPost.Simple.fromJs);
  var trendingPosts = Belt_Array.map(props.trendingPosts, BlogPost.Simple.fromJs);
  var userRole = Role.fromString(props.context.userRole);
  return JsxRuntime.jsx(BlogPostShow$Component, {
              blogPost: blogPost,
              latestPosts: latestPosts,
              trendingPosts: trendingPosts,
              userRole: userRole
            });
}

var make = BlogPostShow$Component;

var $$default = BlogPostShow$default;

export {
  css ,
  Helpers ,
  Component ,
  make ,
  $$default as default,
}
/* css Not a pure module */

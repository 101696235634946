// extracted by mini-css-extract-plugin
export var adPost = "BlogPostShow__adPost__xNX5B";
export var authorContainer = "BlogPostShow__authorContainer__SZH1g";
export var authorDescription = "BlogPostShow__authorDescription__pp1O5";
export var authorHeader = "BlogPostShow__authorHeader__NmGNU";
export var authorImg = "BlogPostShow__authorImg__L6GYm";
export var authorImgContainer = "BlogPostShow__authorImgContainer__KFPzj";
export var authorImgPlaceholder = "BlogPostShow__authorImgPlaceholder__OuqqO";
export var authorMetaContainer = "BlogPostShow__authorMetaContainer__ex9P9";
export var authorName = "BlogPostShow__authorName__SNO2W";
export var blogPost = "BlogPostShow__blogPost__idHcj";
export var blogPostContainer = "BlogPostShow__blogPostContainer__oV1Kz";
export var blogPostContent = "BlogPostShow__blogPostContent__ZlKpV";
export var blogPostLayout = "BlogPostShow__blogPostLayout__DXYsZ";
export var blogTitle = "BlogPostShow__blogTitle__OwUYM";
export var column = "BlogPostShow__column__QGx1Q";
export var featureImage = "BlogPostShow__featureImage__ISAbI";
export var flex = "BlogPostShow__flex__o7x1e";
export var flexColumn = "BlogPostShow__flexColumn__eiA19";
export var gap1 = "BlogPostShow__gap1__YCVzI";
export var gap2 = "BlogPostShow__gap2__HXuxT";
export var gap3 = "BlogPostShow__gap3___E0Ug";
export var gap4 = "BlogPostShow__gap4__tGTO2";
export var gap5 = "BlogPostShow__gap5__KIb5E";
export var postContent = "BlogPostShow__postContent__zT74G";
export var publishedMeta = "BlogPostShow__publishedMeta__U4sYK";
export var row = "BlogPostShow__row__ZXGfc";
export var sidebar = "BlogPostShow__sidebar__FLHci";
export var tag = "BlogPostShow__tag__ZeRvT";
export var tagsContainer = "BlogPostShow__tagsContainer__k2jzd";